import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

function textureLoader(basePath, { onProgress } = {}) {
  const textureManager = new THREE.LoadingManager();
  textureManager.onProgress = (_, itemsLoaded, itemsTotal) => {
    if (onProgress) {
      onProgress(itemsLoaded / itemsTotal);
    }
  };
  return new THREE.TextureLoader(textureManager).setPath(basePath);
}

function loadTexture(src, loader) {
  return new Promise((resolve) => {
    loader.load(src, (texture) => {
      // 设置贴图包裹方式
      texture.needsUpdate = true;
      // texture.repeat.y = -1;
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      resolve(texture);
    });
  });
}

function loadVideo(video) {
  const texture = new THREE.VideoTexture(video);
  texture.needsUpdate = true;
  return texture;
}

function loadModel({ src, loader, onProgress = () => {} }) {
  return new Promise((resolve) => {
    loader.load(
      src,
      (model) => {
        resolve(/\.(gltf)$/gi.test(src) ? model.scene : model);
      },
      (xhr) => {
        if (onProgress) {
          // console.log(xhr.loaded / xhr.total, xhr.loaded, xhr.total, xhr);
          onProgress(xhr.loaded / xhr.total);
        }
      },
    );
  });
}

function loadFbx(src, { onProgress } = {}) {
  const loader = new FBXLoader();
  return loadModel({ src, loader, onProgress });
}

function loadGltf(src, { onProgress } = {}) {
  const loader = new GLTFLoader();
  return loadModel({ src, loader, onProgress });
}

function loadObj(src, { onProgress } = {}) {
  const loader = new THREE.ObjectLoader();
  return loadModel({ src, loader, onProgress });
}

export { textureLoader, loadTexture, loadModel, loadFbx, loadGltf, loadObj, loadVideo };
